import withRoot from '../utils/withRoot';
import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../components/SEO';
import Card from '../components/Card';
import Page from '../components/Page';
import HomeFeatures from '../components/HomeFeatures';
import Button from '@material-ui/core/Button';
import Carousel from '../components/Carousel';
import Avatar from '@material-ui/core/Avatar';
import { Factory } from 'mdi-material-ui';
import withStyles from '@material-ui/styles/withStyles';

const styles = (theme) => ({
		root: {
			fontWeight: 'bold'
		}
	}),
	Home = (props) => {
		const products = props.data.allMarkdownRemark.edges;
		return (
			<Page title="Vārtu ražotne">
				<SEO title="Sākums">
					<meta
						name="description"
						content="Vārtu, žogu ražotne, vārtu automātika. Augstas kvalitātes vārtu risinājumi: automātiskie garāžu vārti, paceļamie vārti, ruļļu vārti, industriālie vārti."
					/>
				</SEO>

				<HomeFeatures />
				<Card
					title="Mūsu produkcija"
					avatar={
						<Avatar>
							<Factory />
						</Avatar>
					}
					action={
						<Button
							variant="contained"
							color="secondary"
							className={props.classes.root}
							component={Link}
							to="/products"
						>
							Apskatīt visus produktus
						</Button>
					}
					style={{ minHeight: 523 }}
				>
					<Carousel items={products} />
				</Card>
			</Page>
		);
	};

export const query = graphql`
	query {
		allFile(filter: { extension: { eq: "jpg" } }) {
			edges {
				node {
					publicURL
				}
			}
		}
		allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/products/" } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						image {
							publicURL
						}
						path
						title
						date(formatString: "DD MMMM YYYY")
					}
					excerpt
				}
			}
		}
	}
`;

Home.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Home));
