import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/styles/withStyles';
import { QualityHigh, CurrencyEur } from 'mdi-material-ui';

const styles = (theme) => ({
		featureChip: {
			fontSize: '16px',
			backgroundColor: '#fff',
			border: '1pt solid #eee'
		},
		featureChipRight: {
			fontSize: '16px',
			backgroundColor: '#fff',
			border: '1pt solid #eee',
			float: 'right'
		},
		featureGrid: {
			marginBottom: '25px'
		},
		avi: {
			width: '40px',
			height: '40px',
			color: '#fff',
			backgroundColor: theme.palette.secondary.main
		}
	}),
	HomeFeatures = (props) => {
		return (
			<Grid spacing={36} container justify="center" className={props.classes.featureGrid}>
				<Grid item md={6}>
					<Chip
						className={props.classes.featureChipRight}
						avatar={
							<Avatar className={props.classes.avi}>
								<CurrencyEur />
							</Avatar>
						}
						label="Izdevīgi"
					/>
				</Grid>
				<Grid item md={6}>
					<Chip
						className={props.classes.featureChip}
						avatar={
							<Avatar className={props.classes.avi}>
								<QualityHigh />
							</Avatar>
						}
						label="Kvalitātīvi"
					/>
				</Grid>
			</Grid>
		);
	};

HomeFeatures.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HomeFeatures);
